<template>
    <div class="main">
        <div class="search">

        </div>

        <div class="data" style="overflow-y: scroll;max-height: 850px;background-color: #fff9ff">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="종류설정" name="kindConfig">
                    <el-table
                            highlight-current-row
                            :data="kindCofingList"
                            style="width: 100%"
                            max-height="750"
                            border>
                        <el-table-column
                                fixed
                                label="idx"
                                width="100">
                            <template slot-scope="scope">
                                {{scope.row.idx}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="종류명"
                                width="250">
                            <template slot-scope="scope">
                                {{scope.row.kindName}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="마감전 초수"
                                width="300">
                            <template slot-scope="scope">
                                <el-input size="mini" v-model="scope.row.endsecond" style="width: 50%"></el-input>'초 전마감
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="회차당 베팅가능 횟수"
                                width="330">
                            <template slot-scope="scope">
                                회차당 <el-input size="mini" v-model="scope.row.betablecount" style="width: 50%"></el-input> 회 베팅
                            </template>
                        </el-table-column>

                        <el-table-column
                                fixed
                                label="상태"
                                width="150">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.playStatus" size="mini"
                                           placeholder="운영" style="width: 90%;">
                                    <el-option label="정상" :value="managerConst.ENABLE">정상</el-option>
                                    <el-option label="STOP" :value="managerConst.DISABLE" >STOP</el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="상태표기"
                                width="70">
                            <template slot-scope="scope">
                                <span style="color: red" v-if="scope.row.playStatus == managerConst.DISABLE"> <i class="fa fa-close"></i></span>
                                <span style="color: #0000ff" v-if="scope.row.playStatus == managerConst.ENABLE"> <i class="fa fa-check"></i></span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="action"
                                width="120">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" @click="updateLeisureKind(scope.row)">수정하기</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>


                <el-tab-pane label="속성설정" name="attrConfig">
                    <el-table
                            :data="attrCofingList"
                            style="width: 100%"
                            max-height="750"
                            border>
                        <el-table-column
                                fixed
                                label="idx"
                                width="80">
                            <template slot-scope="scope">
                                {{scope.row.idx}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="속성명"
                                width="180">
                            <template slot-scope="scope">
                                {{scope.row.attrName}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="way"
                                width="50">
                            <template slot-scope="scope">
                                {{scope.row.way}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="베팅수수료"
                                width="120">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.givebackpointable" size="mini"
                                           placeholder="베팅수수료" style="width: 90%;">
                                    <el-option label="정상" :value="managerConst.ENABLE">정상</el-option>
                                    <el-option label="STOP" :value="managerConst.DISABLE" >STOP</el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="상태"
                                width="120">
                            <template slot-scope="scope">
                                <el-select v-model="scope.row.playStatus" size="mini"
                                           placeholder="운영" style="width: 90%;">
                                    <el-option label="정상" :value="managerConst.ENABLE">정상</el-option>
                                    <el-option label="STOP" :value="managerConst.DISABLE" >STOP</el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                        <el-table-column
                                fixed
                                label="상태표기"
                                width="70">
                            <template slot-scope="scope">
                                <span style="color: red" v-if="scope.row.playStatus == managerConst.DISABLE"> <i class="fa fa-close"></i></span>
                                <span style="color: #0000ff" v-if="scope.row.playStatus == managerConst.ENABLE"> <i class="fa fa-check"></i></span>
                            </template>
                        </el-table-column>
                        <el-table-column

                                label="배당1"
                                width="100">
                            <template slot-scope="scope">
                                <el-input style="width: 90%" type="number" size="mini" v-model="scope.row.odds1"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column

                                label="배당2"
                                width="100">
                            <template slot-scope="scope">
                                <el-input style="width: 90%" type="number" size="mini" v-model="scope.row.odds2"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column

                                label="배당3"
                                width="100">
                            <template slot-scope="scope">
                                <el-input style="width: 90%" type="number" size="mini" v-model="scope.row.odds3"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="배당4"
                                width="100">
                            <template slot-scope="scope">
                                <el-input style="width: 90%" type="number" size="mini" v-model="scope.row.odds4"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="배당5"
                                width="100">
                            <template slot-scope="scope">
                                <el-input style="width: 90%" type="number" size="mini" v-model="scope.row.odds5"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="배당6"
                                width="100">
                            <template slot-scope="scope">
                                <el-input style="width: 90%" type="number" size="mini" v-model="scope.row.odds6"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="action"
                                width="120">
                            <template slot-scope="scope">
                                <el-button size="mini" type="primary" @click="updateLeisureAttr(scope.row)">수정하기</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-tab-pane>
            </el-tabs>
        </div>

    </div>

</template>

<script>

    import OnlineUserComp from "../../components/administrator/OnlineUserComp";
    import TodayStatisticComp from "../../components/administrator/TodayStatisticComp";
    import ManagerTopbarComp from "../../components/administrator/ManagerTopbarComp";
    import {Loading} from "element-ui";
    import {getHonorCasinoBetlist, sysData} from "../../network/manager/honorCasinoRequest";
    import {manager} from "../../common/administrator/managerMixin";
    import DateSelectorComp from "../../components/administrator/DateSelectorComp";
    import Vue from "vue";
    import UserEditToast from "../../components/administrator/UserPanel/UserEditToast";
    import {getRegisterCodeList, updateRegisterCode} from "../../network/manager/registerCodeRequest";
    import managerConst from "../../common/administrator/managerConst";
    import {getRankList} from "../../network/manager/rankRequest";
    import {getGroupList} from "../../network/manager/groupRequest";
    import PartnerEditToast from "../../components/administrator/UserPanel/PartnerEditToast";
    import {
        getLeisureAttrList,
        getLeisureKindList, updateLeisureAttr,
        updateLeisureKind
    } from "../../network/manager/leisureConfigRequest";

    Vue.prototype.$userEditToast = UserEditToast
    Vue.prototype.$partnerEditToast = PartnerEditToast

    export default {
        name: "ManagerLeisureConfig",
        mixins: [manager],
        components: {
            DateSelectorComp,
            ManagerTopbarComp, TodayStatisticComp, OnlineUserComp
        },
        data() {
            return {
                startDate: '',
                endDate: '',
                attrCofing: {},
                attrCofingList: [],
                kindCofing: {},
                kindCofingList: [],
                activeName: 'kindConfig',
            }
        },
        methods: {
            getKindConfigList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getLeisureKindList().then(res => {
                    this.kindCofingList = res.data.data
                    loadingInstance.close()
                })
            },
            getAttrConfigList() {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                getLeisureAttrList().then(res => {
                    this.attrCofingList = res.data.data
                    loadingInstance.close()
                })
            },
            updateLeisureKind(item) {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updateLeisureKind(item).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                    loadingInstance.close()
                    this.getKindConfigList()
                })
            },
            updateLeisureAttr(item) {
                let loadingInstance = Loading.service({
                    lock: true,
                    text: 'Loading',
                    spinner: 'el-icon-loading',
                    background: 'rgba(0, 0, 0, 0.7)'
                });
                updateLeisureAttr(item).then(res => {
                    if (res.data.success) {
                        this.$message({
                            type: 'success',
                            duration: 1000,
                            message: '업데이트 완료되였습니다'
                        });
                    } else {
                        this.$message({
                            showClose: true,
                            duration: 3000,
                            message: res.data.msg,
                            type: 'error'
                        });
                    }
                    loadingInstance.close()
                    this.getAttrConfigList()
                })
            },


        },
        created() {
            this.getKindConfigList()
            this.getAttrConfigList()
        },
    }
</script>

<style scoped>

</style>